import { Card, CardContent, CardHeader, Container, styled } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import Browser from "./Browser"
import chromeIcon from "./images/chrome.png"
import chromiumGostIcon from "./images/chromium_gost.png"
import edgeIcon from "./images/edge.png"
import operaIcon from "./images/opera.png"
import safariIcon from "./images/safari.png"
import yandexIcon from "./images/yandex.png"

const UBContainer = styled(Container)(() => ({
    marginTop: "50px",
}))

const UBCard = styled(Card)(() => ({
    borderRadius: "10px",
}))

const Title = styled(CardHeader)(() => ({
    "& .MuiTypography-root": {
        fontSize: "1.1rem",
        fontWeight: "500",
        textAlign: "center",
        whiteSpace: "pre-line",
    }
}))

const Content = styled(CardContent)(({ theme }) => ({
    alignItems: "center",
    display: "grid",
    gap: "25px",
    gridTemplateColumns: "repeat(3, 1fr)",
    justifyItems: "start",
    justifySelf: "center",
    [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "repeat(2, 1fr)",
    },
}))

const UnsupportedBrowser = () => {
    const { t } = useTranslation()

    return (
        <UBContainer maxWidth="md">
            <UBCard>
                <Title title={t("usd.unsupportedBrowser.card.title")} />
                <Content>
                    <Browser href="https://browser.yandex.ru" icon={yandexIcon} name="Яндекс" />
                    <Browser href="https://www.google.com/intl/ru_ru/chrome" icon={chromeIcon} name="Chrome" />
                    <Browser href="https://www.opera.com/ru" icon={operaIcon} name="Opera" />
                    <Browser href="https://www.microsoft.com/ru-ru/edge" icon={edgeIcon} name="Edge" />
                    <Browser href="https://www.apple.com/safari" icon={safariIcon} name="Safari" />
                    <Browser
                        href="https://www.cryptopro.ru/products/chromium-gost"
                        icon={chromiumGostIcon}
                        name="Chromium-Gost"
                    />
                </Content>
            </UBCard>
        </UBContainer>
    )
}

export default UnsupportedBrowser
