import { Link, styled, Typography } from "@mui/material"
import React from "react"

const BrowserLink = styled(Link)(() => ({
    alignItems: "center",
    color: "inherit",
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    textDecoration: "none",
}))

const Name = styled(Typography)(() => ({
    fontSize: "0.9rem",
}))

const Browser = ({ href, icon, name }) => {
    return (
        <BrowserLink href={href} target="_blank">
            <img alt={name} src={icon} style={{ height: "32px", width: "32px" }} />
            <Name>{name}</Name>
        </BrowserLink>
    )
}

export default Browser
