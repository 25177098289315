import {
  Card,
  CardContent,
  Container,
  styled,
  Typography,
} from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { ReactComponent as LogoSvg } from "./images/logo.svg"

const HeaderContainer = styled(Container)(() => ({
  paddingTop: "25px",
}))

const HeaderCard = styled(Card)(() => ({
  borderRadius: "10px",
}))

const Content = styled(CardContent)(({ theme }) => ({
  "alignItems": "center",
  "display": "flex",
  "gap": "25px",
  "justifyContent": "center",
  "padding": "10px",
  ":last-child": {
    paddingBottom: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}))

const Logo = styled(LogoSvg)(() => ({
  overflow: "visible",
}))

const Title = styled(Typography)(() => ({
  fontSize: "1.2rem",
  fontWeight: "500",
  textAlign: "center",
  whiteSpace: "pre-line",
}))

const Header = () => {
  const { t } = useTranslation()

  return (
    <HeaderContainer maxWidth="md">
      <HeaderCard>
        <Content>
          <Logo />
          <Title>{t("usd.header.title")}</Title>
        </Content>
      </HeaderCard>
    </HeaderContainer>
  )
}

export default Header
