import React from "react"

import Loader from "../../loader/Loader"
import Iframe from "../../meet/Iframe"
import { checkBrowserSupport } from "../functions"
import { useUsdReadyState } from "../hooks"
import Form from "./Form"
import Header from "./Header"
import Page from "./Page"
import UnsupportedBrowser from "./UnsupportedBrowser"

const Usd = (props) => {
  const { conferenceServer } = useUsdReadyState()

  if (!conferenceServer) {
    return <Loader preloader={true} />
  }

  const isBrowserSupported = checkBrowserSupport()

  /**
   * The match params passed by React Router (App.js)
   */
  const confId = props.match?.params?.confId
  const uslugirtToken = new URLSearchParams(window.location.search).get("one_time_token")

  if (isBrowserSupported && confId && uslugirtToken) {
    return (
      <Iframe
        info={{ uslugirtToken }}
        locationHost={conferenceServer}
        meetId={confId}
      />
    )
  }

  return (
    <Page>
      <Header />
      {isBrowserSupported
        ? <Form />
        : <UnsupportedBrowser />}
    </Page>
  )
}

export default Usd
