import { Box, styled } from "@mui/material"
import React from "react"

import bg from "./images/bg.jpg"

const UsdPage = styled(Box)(() => ({
  backgroundImage: `url(${bg})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  minHeight: "100%",
}))

const Page = ({ children }) => {
    return <UsdPage>{children}</UsdPage>
}

export default Page
